var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height pa-0 ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-5 ma-1",
                  attrs: { elevation: "17", raised: "", width: "380px" }
                },
                [
                  _c("v-container", { staticClass: "ma-0 logo-wrapper" }, [
                    _c("img", {
                      attrs: { src: require("../assets/mappa_logo.png") }
                    })
                  ]),
                  _c("v-card-title", [_vm._v("Verificação de Email")]),
                  _c(
                    "v-card-text",
                    { staticClass: "pr-0" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          !_vm.verifyingEmail
                            ? _c("span", { staticClass: "subtitle-1" }, [
                                _vm._v("Seu email foi verificado com sucesso!")
                              ])
                            : _vm._e(),
                          !_vm.verifyingEmail
                            ? _c("span", { staticClass: "subtitle-1" }, [
                                _vm._v("Você será redirecionado.")
                              ])
                            : _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: 30
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }