<template>
  <div
    class="bg fill-height"
  >
    <v-container
      fluid
      class="fill-height ma-0 pa-0"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height pa-0 ma-0"
      >
        <v-card
          elevation="17"
          raised
          class="pa-5 ma-1"
          width="380px"
        >
          <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>

          <v-card-title>Verificação de Email</v-card-title>

          <v-card-text class="pr-0">
            <v-row
              class="ma-0"
            >

                <span v-if="!verifyingEmail" class="subtitle-1">Seu email foi verificado com sucesso!</span>
                <span v-if="!verifyingEmail" class="subtitle-1">Você será redirecionado.</span>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
                :size="30"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: "VerifyEmail",
    data: () => ({
      verifyingEmail: true,
    }),
    mounted() {
      const verifyQuery = this.$route.query
      const verifyToken = verifyQuery["t"]
      if (!verifyToken) {
        this.verifyingEmail = false
        return
      }

      this.$apiAuth.verifyEmail(verifyToken)
        .then(() => {
          this.verifyingEmail = false
          setTimeout(() => {
            this.$router.push("/login?fl=1")
          }, 3000)
        })
        .catch(() => {
        this.verifyingEmail = false
      })
    },
  }
</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .v-card {
    max-width: 380px;

    @include display-xs-only {
      width: 100% !important;
      min-height: 100% !important;
      padding: 10px;
      margin: 0px !important;
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }
</style>
